import "./Equalizer.css";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";

type Props = {
    svgRef: any;
};

export const Equalizer = observer(({ svgRef }: Props) => {
    return <Fragment>
        <div className="equalizer-fg"></div>
        <div className="equalizer-bg" ref={svgRef}></div>
    </Fragment>

});

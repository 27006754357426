import "./SongControls.css";

import { observer } from "mobx-react-lite";
import { makeFromObjectMap } from "shared/lib/makeFromObjectMap";
import { rmdIcons } from "shared/icons";

import { ControlBtn } from "./ui/ControlBtn/ControlBtn";
import { SongFavoriteModel } from "../../model/SongFavoriteModel";
import { ISongControlOption, ISongActionsMap } from "./types";

type Props = {
    model: SongFavoriteModel;
    controlsMap: ISongActionsMap;
    isAuth: boolean;
};

export const SongControls = observer(({
    model,
    controlsMap,
    isAuth,
}: Props) => {
    const songActions = makeFromObjectMap(controlsMap);
    const onItemAction = (item: ISongControlOption) => item.action(model);

    return (
        <div className="song-controls">
            {songActions.map(({ key, item }) => <ControlBtn
                key={key}
                isAvailable={item.isAvailable}
                checkIsActive={item.checkIsActive}
                icon={key as keyof typeof rmdIcons}
                action={() => onItemAction(item)}
                itemCount={item.itemCount}
                actionName={item.actionName}
                isAuth={isAuth}
                model={model}
            />)}
        </div>
    );
});
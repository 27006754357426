import { observer } from "mobx-react-lite";
import { ControlBtnType } from "../../types/ControlBtnType";
import { RmdIcon } from "../../../../shared/ui/RmdIcon";
import "./ControlBtn.css";
import {playerIcons} from "../../../../widgets/Player/icons";

type Props = {
    type: ControlBtnType;
    action: () => void;
    icon: keyof typeof playerIcons;
};

export const ControlBtn = observer(({ type, action, icon }: Props) => {
    return (
        <span onClick={action} className={`control-btn control-btn_${type}`}>
            { <RmdIcon customCollection={playerIcons} type={icon} />}
        </span>
    );
});

import { observer } from "mobx-react-lite";
import { VolumeSlider } from "./VolumeSlider";
import { VolumeBtn } from "./VolumeBtn";
import { VolumeBarStore } from "../../../../features/VolumeBar";
import "./VolumeBar.css";

type Props = {
  store: VolumeBarStore;
  isVertical?: boolean;
};

export const VolumeBar = observer(({
    store,
    isVertical = false
}: Props) => {
    const { volume } = store;
    return (
        <div className="volume-bar">
            {!isVertical && <VolumeBtn
                mute={store.mute}
                setMute={() => store.toggleMute()}
            />}
            <VolumeSlider
                volume={volume}
                setVolume={store.setVolume}
                isVertical={isVertical}
            />
        </div>
    );
});

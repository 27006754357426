import "./ControlBtn.css";

import { useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { SongFavoriteModel } from "entities/Song";
import { rmdIcons } from "shared/icons";
import { IconButton } from "shared/ui/IconButton";
import { RmdIcon } from "shared/ui/RmdIcon";
import cn from "classnames";

type Props = {
    icon: keyof typeof rmdIcons;
    action: Function;
    isAvailable: boolean;
    checkIsActive: (model: SongFavoriteModel) => boolean;
    isAuth: boolean;
    model: SongFavoriteModel;
    itemCount?: () => number;
    actionName?: string;
};

export const ControlBtn = observer(({
    icon,
    action,
    checkIsActive,
    isAvailable,
    isAuth,
    model,
    itemCount,
    actionName,
}: Props) => {
    const [likes, setLikes] = useState(() => (itemCount && itemCount()) ?? 0);
    const [isActive, setIsActive] = useState(false);

    const handleChangeState = async () => {
        if (!(isAuth && isAvailable)) return;
        action();

        if (actionName === "like") {
          setIsActive(!isActive);
          if (isActive) {
            setLikes(prev => prev > 1 ? prev - 1 : prev);
          } else {
            setLikes(prev => prev + 1);
          }
        }
    };

    useLayoutEffect(() => {
        if (model && isActive) {
            console.log("checkIsActive", {  isActive: checkIsActive(model) });
        }
    }, [model]);

    return (
        <div className="control-btn">
            {!!likes &&
                <span className="control-btn__like-count">{likes}</span>
            }
            <IconButton
                className={cn(!isAvailable && "icon-button_inactive")}
                onClick={handleChangeState}
                isDisabled={!isAvailable}
            >
                <RmdIcon type={isActive ? `${icon}Active` : icon} />
            </IconButton>
        </div>
    );
});
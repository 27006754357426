import * as d3 from "d3";
import { RefObject } from "react";

export class Visualization {
    requestId: number | null = null;
    audioContext: AudioContext | null = null;
    analyser: AnalyserNode | null = null;
    audioSource: MediaElementAudioSourceNode | null = null;
    svgRef: RefObject<SVGSVGElement> | null = null;
    height = 162;

    init(audioRef: any, svgRef: any) {
        if (!this.audioContext) {
            // Создаем AudioContext и AnalyserNode только при первом воспроизведении
            this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext || (window as any).mozAudioContext)();
            this.analyser = this.audioContext.createAnalyser();
            this.audioSource = this.audioContext.createMediaElementSource(audioRef.current);
            this.audioSource.connect(this.analyser);
            this.analyser.connect(this.audioContext.destination);
            this.analyser.fftSize = 64;
            this.svgRef = svgRef;

            window.addEventListener("resize", this.debounce(() => this.drawBars()));
        }

        return this;
    }

    debounce<T extends Function>(func: T, timeout = 300) {
        let h: number | NodeJS.Timeout;
        let callable = (...args: any) => {
            clearTimeout(h);
            h = setTimeout(() => func(...args), timeout);
        };
        return <T>(<any>callable);
    }

    clearEqualizer = () => {
        if (this.requestId) {
            window.cancelAnimationFrame(this.requestId);
            this.requestId = null;
        }
        if (this.svgRef) {
            d3.select(this.svgRef.current).selectAll("*").remove();
            // window.removeEventListener("resize", this.debounce(() => this.drawBars()));
        }
    };

    drawBars = () => {
        if (this.svgRef) {
            this.clearEqualizer();

            const barRatio = 0.12;
            const rows = 20;
            const cols = 24;
            const gapY = 3;

            const rectWidth = Math.floor(window.innerWidth / ((barRatio + 1) * cols - barRatio));
            const totalGapX = Math.round(rectWidth * barRatio);
            const rectHeight = (this.height - (rows - 1) * gapY) / rows;

            const svg = d3.select(this.svgRef.current)
                .append('svg')
                .attr('width', cols * rectWidth + (cols - 1) * totalGapX)
                .attr('height', this.height);

            const rectangles: d3.Selection<SVGRectElement, unknown, null, undefined>[][] = [];

            for (let i = 0; i < cols; i++) {
                rectangles[i] = [];
                for (let j = 0; j < rows; j++) {
                    rectangles[i][j] = svg.append('rect')
                        .attr('x', i * (rectWidth + totalGapX))
                        .attr('y', j * (rectHeight + gapY))
                        .attr('width', rectWidth)
                        .attr('height', rectHeight)
                        .attr('rx', 0.5 * rectHeight);
                }
            }

            const renderFrame = (rectangles: d3.Selection<SVGRectElement, unknown, null, undefined>[][]) => {
                if (this.analyser) {
                    const audioData = new Uint8Array(this.analyser.frequencyBinCount);
                    this.analyser.getByteFrequencyData(audioData);
                    const step = this.height / 255;

                    for (let i = 0; i < rectangles.length; i++) {
                        for (let j = 0; j < rectangles[i].length; j++) {
                            const amplitude = this.height - audioData[i] * step;
                            const fill = j * (rectHeight + gapY) >= amplitude ? "#7978AA" : "#2A2A54";
                            rectangles[i][j].attr('fill', fill);
                        }
                    }

                    this.requestId = requestAnimationFrame(() => renderFrame(rectangles));
                }
            };

            renderFrame(rectangles);
        }
    }


}
import { AuthorizationProcess } from "processes/AuthotizationProcess";
import { EventEmitter } from "shared/lib/EventEmitter";

import { AppMediaStore } from "./AppMediaStore";

type EntityListEvents = {
    "authSuccess": () => void;
};

export class AppStore extends EventEmitter<EntityListEvents> {
    private static shared: AppStore | null;

    public appMediaStore = AppMediaStore.getShared();

    public authorizationProcess = AuthorizationProcess.getShared();

    constructor() {
        super();

        /** После разлогинивания обновляем список станций */
        this.authorizationProcess.authStore.on("logoutSuccess", () => {
            this.appMediaStore.stationSwitchStore.emit("fetchStationList");
        });

        /** После успешной авторизации запрашиваются лайкнутые треки */
        this.authorizationProcess.authStore.on("authUserDataReceived", () => {
            this.appMediaStore.favouriteStore.emit("fetchLikedSongs");
        });

        this.appMediaStore.favouriteStore.on("songHasBeenLiked", () => {
           this.appMediaStore.stationSwitchStore.emit("fetchStationList");
        });
    }

    static getShared(): AppStore {
        if (this.shared) {
            return this.shared;
        }
        this.shared = new AppStore();
        return this.shared;
    }
}
import { action, makeObservable, observable } from "mobx";

import { Visualization } from "../../Equalizer/ui/Visualization";

type PlayerStateType = false | true;

export class PlayerControlStore {
    private static shared: PlayerControlStore | null;

    isPlaying: boolean = false;

    visualization = new Visualization();

    audioRef: any;

    svgRef: any;

    constructor() {
        makeObservable(this, {
            isPlaying: observable,
            setPlaying: action
        })
    }

    static getShared(): PlayerControlStore {
        if (this.shared) {
            return this.shared;
        }
        this.shared = new PlayerControlStore();
        return this.shared;
    }

    checkPlaying = () => {
        if (!this.audioRef) return false;

        return this.audioRef.current &&
          this.audioRef.current.currentTime > 0 &&
          !this.audioRef.current.paused &&
          !this.audioRef.current.ended;
    }

    togglePlay = (play: boolean, url: string = "") => {
        if (!this.audioRef) return;

        if (this.checkPlaying() && !play) {
            this.audioRef.current?.pause();
            this.visualization.clearEqualizer();
            this.setPlaying(false);
        } else {
            if (this.audioRef.current) {
                this.audioRef.current.src = url;
                this.audioRef.current.play().catch((err: { code: number }) => {
                    if (err.code != 20) {
                        console.log("err", err);
                    }
                });
                this.visualization.init(this.audioRef, this.svgRef);
                this.visualization.drawBars();
                this.setPlaying(true);
                // visualization.drawWave(analyser);
            }

        }
    };

    setPlaying(state: PlayerStateType) {
        this.isPlaying = state;
    }
}
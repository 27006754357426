import { Fragment, useEffect, useState } from "react";
import "./TimeLine.css";
import { observer } from "mobx-react-lite";

type Props = {
    time: any;
    timeLineRef: any;
};

export const TimeLine = observer(({ time, timeLineRef }: Props) => {

    const secondsToMS = (second: number) => {
        if (second) {
            return new Date(second * 1000).toISOString().slice(14, 19);
        }
    };

    const setProgress = (second: number) => {
        if (second && timeLineRef.current) {
            if (time.duration)
                timeLineRef.current.style.width =
                    (second / time.duration) * 100 + "%";
            else timeLineRef.current.style.width = "100%";
        }
    };

    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        setElapsedTime(time.elapsed);
        const interval = setInterval(() => {
            setElapsedTime((prev) => prev + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    useEffect(() => {
        setProgress(elapsedTime);
    }, [elapsedTime]);

    return (
        <Fragment>
            <div className="time-line__duration">
                <span>{secondsToMS(elapsedTime)}</span>
                <span>{secondsToMS(time.duration)}</span>
            </div>
            <div className="time-line__progress">
                <div className="time-line__done" ref={timeLineRef} />
            </div>
        </Fragment>
    );
});

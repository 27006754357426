import { action, makeObservable, observable } from "mobx";

export class VolumeBarStore {
    volume: string = localStorage.getItem('volume') ?? "1";
    mute: boolean = false;

    constructor() {
        this.setVolume = this.setVolume.bind(this);

        makeObservable(this, {
            volume: observable,
            mute: observable,
            setVolume: action,
            toggleMute: action
        })
    }

    setVolume(volume: string) {

        this.volume = volume;
        this.mute = Number(volume) === 0;

        if (!this.mute)
            localStorage.setItem('volume', volume);

        let progress: string = +volume * 100 + "%";
        const volume_slider = document.querySelector('.progress-done') as HTMLElement;

        if (volume_slider) {
            volume_slider.style.width = progress;
        }

        const audio = document.getElementById("audio") as HTMLMediaElement;
        if (audio) {
            audio.volume = +volume;
        }
    }

    toggleMute() {
        this.mute = !this.mute;
        this.mute ? this.setVolume("0") : this.setVolume(localStorage.getItem('volume') ?? "1");
    }
}